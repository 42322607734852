export class Footer {
  constructor() {
    this.subHeadingsEls = document.querySelectorAll('.js-subHeading');
    this.linksEls = document.querySelectorAll('.js-linksWrapper');
    this.chevrons = document.querySelectorAll('.js-subHeading');
    this.mobileBP = window.matchMedia(`(max-width: 767px)`);
    this.handleBreakpoint();
    this.mobileBP.addListener(this.handleBreakpoint.bind(this));
    this.disclaimerLinks();
    this.setupOneTrustLink();
  }

  handleBreakpoint() {
    if (this.mobileBP.matches) {
      this.enableMobileFooter();
    } else {
      this.resetMobileFooter();
    }
  }

  enableMobileFooter() {
    for (let linkEl of this.linksEls) {
      linkEl.classList.add('js-hide');
    }

    for (let subHeadingEl of this.subHeadingsEls) {
      subHeadingEl.addEventListener('click', (evt) => {
        const headingEl = evt.target;
        const parentEl = headingEl.parentElement;
        const linksEl = parentEl.querySelector('.js-linksWrapper');
        const chevron = parentEl.querySelector('.js-chevron');

        chevron.classList.toggle('js-isActive');
        if(headingEl.classList.contains('js-isOpen')) {
          headingEl.classList.remove('js-isOpen');
          headingEl.setAttribute("aria-expanded", false);
        } else {
          headingEl.classList.add('js-isOpen');
          headingEl.setAttribute("aria-expanded", true);
        }
        if (linksEl) {
          linksEl.classList.toggle('js-hide');
        }
      })
    }
  }

  resetMobileFooter() {
    for (let linkEl of this.linksEls) {
      linkEl.classList.remove('js-hide');
    }

    for (let subHeadingEl of this.subHeadingsEls) {
      subHeadingEl.classList.remove('js-isOpen');
    }
  }

  disclaimerLinks() {
    const disclaimer = document.querySelector('.js-footer-disclaimer');
    if (disclaimer) {
      const links = disclaimer.getElementsByTagName('a');
      for (let i = 0; i < links.length; i++) {
        links[i].target = "_blank";
      }
    }
  }

  setupOneTrustLink() {
    const otLink = document.querySelector('.js-cookieLink');
    if (otLink) {
      otLink.addEventListener('click', () => {
        window.OneTrust.ToggleInfoDisplay();
      })
    }
  }
}
