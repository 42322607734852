function initAppBanner() {
  const el = document.querySelector('.js-appbanner');
  if (!el) { return; }

  const dataEl = document.querySelector('.js-appbanner-data');
  if (!dataEl) { return; }
  const data = JSON.parse(dataEl.innerHTML);

  // Don't show the app banner if it has been dismissed already in this browser
  const hasBeenDismissedBefore = window.localStorage.getItem('yext_appbanner_has_been_dismissed');
  if (hasBeenDismissedBefore === 'true') {
    el.remove();
    return;
  }

  // Don't show the app banner if it is not on a mobile device
  if (!isMobile()) {
    el.remove();
    return;
  }

  // Show the app banner
  // (Control visibility like this to prevent the banner from briefly
  //   flashing on the screen before being removed)
  el.classList.add('is-visible');

  // Update the link to handle android/IOS app stores
  // Soy2js doesn't work so just directly change the HTML element here
  const linkEls = el.querySelectorAll('.js-appbanner-link');
  if (linkEls && linkEls.length > 0 && isIOS()) {
    const appLink = data.appstore || data.playstore || '';
    linkEls.forEach(linkEl => linkEl.href = appLink);
  }

  // Close button
  const closeBtn = el.querySelector('.js-appbanner-close');
  if (closeBtn) {
    closeBtn.addEventListener('click', () => {
      window.localStorage.setItem('yext_appbanner_has_been_dismissed', 'true');
      el.remove();
    });
  }

  // Add CSS class when "position: sticky" is in effect by checking 
  //  if any part of the element is off screen.
  //  (requires CSS "top: -1px" on the sticky element as well)
  const observer = new IntersectionObserver(
    ([e]) => e.target.classList.toggle('is-sticking', e.intersectionRatio < 1),
    { threshold: [1] }
  );
  observer.observe(el);
}

// https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
function isMobile() {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}

// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
function isIOS() {
  return /iPad|iPhone|iPod/.test(navigator.platform)
    || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
}

export {
  initAppBanner,
}