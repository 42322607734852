import { MonitoringInit } from 'js/components/Monitoring/Monitoring.js';
import { Polyfills } from 'js/components/Polyfills/Polyfills.js';
import { Analytics } from 'components-yext-analytics/@yext/components-yext-analytics/Analytics.js';
import { AccessibilityChecks } from 'js/components/Util/Accessibility.js';
import { OnReady } from 'js/components/Util/Browser.js';
import { ImageObjectFit } from 'js/components/Image/index.js';
import { Header } from 'js/common/modules/Header.js';
import { Debug } from "js/components/Util/Debug.js";
import { Instance as WCAGNewTab } from "js/components/Util/WcagNewTab.js";
import { AnalyticsDebugger } from "ts/components/AnalyticsDebugger/AnalyticsDebugger.ts";
import { Footer } from "js/common/modules/Footer.js";
import URI from 'urijs';
import { initAppBanner } from 'js/common/modules/AppBanner';

import 'script-loader!node_modules/svg4everybody/dist/svg4everybody.min.js';

export class Global {
  static init() {
    Polyfills.init();
    MonitoringInit();

    OnReady(() => {
      initAppBanner();
      new Header(991);

      new Footer();

      const thisYear = new Date().getFullYear();

      const dateSpans = document.querySelectorAll('.c-copy-date');
      for (const dateSpan of dateSpans) {
        dateSpan.innerHTML = `&copy; ${thisYear}`;
      }

      ImageObjectFit();

      svg4everybody();
      WCAGNewTab.wcagify();

      if ((Yext.isStaging ||
          window.location.href.includes('yextpages') ||
          window.location.href.includes('localhost'))
          && !window.location.href.includes('search')) {
        AccessibilityChecks.checkAltTags();
      }

      // Provide a global callback so the client's scripts can choose when to enable Yext Analytics
      window.enableYextAnalytics = () => {
        window.yextAnalyticsEnabled = true;
        Yext.Analytics = new Analytics(window);
        window.enableYextAnalytics = () => {};
        if (Debug.isEnabled()) {
          window.Debugger = new AnalyticsDebugger(Yext.Analytics);
        }
      };

      if (window.yextAnalyticsEnabled || Debug.isEnabled()) {
        window.enableYextAnalytics();
      }

      // Send page view
      const uri = new URI(window.location.href);
      window.nbs_ddl = window.nbs_ddl || {};
      nbs_ddl.page = {
        'name': 'nbf' + uri.pathname().toLowerCase().replaceAll('/', ':').replaceAll('-', ' '),
        'responsive_state': window.innerWidth >= 992 ? 'large' : 'small'
      };

      // If internal link click add `navigation` info to pageview analytics object
      if(document.referrer && new URL(document.referrer).hostname == window.location.hostname) {
        const linkData = sessionStorage.getItem('linkText');
        nbs_ddl.page['navigation'] = {
          'element': {
            'type': 'primary link',
            'identifier': linkData
          }
        }
      }

      if (window.checkAnalyticsLoaded && typeof window.checkAnalyticsLoaded === 'function') {
        window.checkAnalyticsLoaded();
      } 

      // Add listeners to different links/buttons to trigger analytics event
      const allLinkButtonToBeProcessed = document.querySelectorAll('a, button');
      nbs_ddl.action = nbs_ddl.action || [];
      allLinkButtonToBeProcessed.forEach(link => 
        {
          link.addEventListener('click', () => {
            const href = link.href;
            if(href && href.startsWith('tel:')) {
              nbs_ddl.action.unshift(
                {
                  'type': 'click to call',
                  'component': {
                    'type': 'link',
                    'identifier': link.href.replace('/[^0-9]/g', '')
                  }
                }
              );
              wa_action();
            } else if(href && new URI(href).domain() == uri.domain()) { // Link with subsequent page view - data added on pageload not click
              // Add sessionstorage variable to be added on subsequent pageview
              sessionStorage.setItem('linkText', link.textContent);
              return;
            } else { // Link/Button without subsequent page view
              nbs_ddl.action.unshift(
                {
                  'type': 'navigation',
                  'element': {
                    'type': 'primary link',
                    'identifier': link.textContent
                  },
                }
              );
              wa_action();
            }
          });
        }
      );
    });
  }
}
