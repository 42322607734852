/* ABOUT THIS FILE
 * The analytics component has been moved to a node module so that we can upgrade
 * all sites if necessary. This file is a shim between the old component and the
 * new module. In the future, all components on new sites will be node modules
 * and this file will no longer be needed.
 */

import { Polyfills } from 'components-yext-analytics/@yext/components-polyfills/Polyfills.js';
import { CalcEventNameMap, Analytics } from 'components-yext-analytics/@yext/components-yext-analytics/Analytics.js';

Polyfills.init();

export {
  Analytics,
  CalcEventNameMap
};
